import messages from '@/common/lang';

export const moduleApplication = {
    state: {
        msg: messages,
        dark: false,
        error: null,
        overlay:false,
        messageOverlay:'',
    },
    mutations: {
        setDark(state, payload) {
            state.dark = payload;
        },
        setError(state, payload) {
            state.error = payload;
        },
        setOverlay(state,payload){
            state.overlay=payload
        },
        setMessageOverlay(state,payload){
            state.messageOverlay=payload
        }
    }
}