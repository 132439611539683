<template> 
  <v-app-bar fixed :clipped-left="clipped" app color="primary" dark  height="55">
    <v-app-bar-nav-icon @click.stop="cambiarDrawer()" v-if="$vuetify.breakpoint.mdAndDown"></v-app-bar-nav-icon>
    <v-toolbar-title class="h6 font-weight-bold">{{tituloPrincipal}}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" v-bind="attrs" icon @click="getDatosRegistro()" :class="{'mr-10':isDevelopment}">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>Actualizar página</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" v-bind="attrs" icon :class="{'ml-10':isDevelopment}">
          <v-icon>mdi-apps</v-icon>
        </v-btn>
      </template>
      <span>Aplicaciones</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" v-bind="attrs" icon>
          <v-badge 
            color="red" 
            overlap
            :value="notificaciones.length"
            :content="notificaciones.length"
            >
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <span>Notificaciones</span>
    </v-tooltip>
    <div v-if="isDevelopment" class="baner-development">
      AMBIENTE DE PRUEBA
    </div>
  </v-app-bar>
</template>
<script>
import { mapState } from "vuex";
import mixinRegistro from "@/mixins/registro";
import mixinAuth from "@/mixins/auth";
export default {
  mixins:[mixinAuth,mixinRegistro],
  computed: {
    ...mapState({
      tituloPrincipal: state => state.menu.tituloPrincipal,
      drawer: state => state.menu.drawer,
      miniVariant: state => state.menu.miniVariant,
      clipped: state => state.menu.clipped,
      notificaciones: state => state.session.notificaciones
    }),
    fotoAvatar() {
      return this.$store.getters.getFotoAbogado;
    },
    isDevelopment(){
      return process.env.VUE_APP_AMBIENTE === 'development'
    }
  },
  methods: {
    cambiarDrawer() {
      this.$store.commit("setDrawer", !this.$store.state.menu.drawer);
    },
    getSessionLocal(){
     this.getSession()
    }
  }
};
</script>
<style>
.baner-development{
    float: right;
    top: 5em;
    right: -5em;
    padding: 10px 20px;
    color: black;
    font-weight: bold;
    letter-spacing: 2px;
    background: #ffb822;
    position: absolute;
    text-align: center;
    transform: rotate(45deg);
    width: 320px;
    font-size: 13px;
    z-index: 999999999;
}
</style>