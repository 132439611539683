export default {
    methods: {
        iniciarSesionModulo(data) {
          var config = {
                params: {
                    token: data.token,
                    refresh_token: data.refresh_token,
                    modulo: data.modulo
                }
            };
            this.$http.get(`${this.$apiUrl}auth/sigin`, config)
                .then(response => {
                    this.$storage.set("token", response.data.data.access_token);
                    this.$storage.set("refresh_token", response.data.data.refresh_token);
                    this.$storage.set("modulos", response.data.data.modulos);
                    this.$storage.set("user", response.data.data.usuario)
                    this.$store.commit('setToken', response.data.data.access_token)
                    this.$store.commit('setRefreshToken', response.data.data.refresh_token)
                    this.$store.commit('setModulos', response.data.data.modulos)
                    this.$store.commit('setUser', response.data.data.usuario)
                    if(response.data.data.usuario.nombreUniversidad!=null){
                        this.$store.commit('addItemsMenu', [
                            {
                                home: true,
                                descripcion:
                                    "Registro de nuevos profesionales por parte de las Universidades y/o Ministerio de Educacion",
                                path: "/registro-certificacion",
                                icon: "mdi-text-box-check-outline",
                                text: "REGISTRO POR CERTIFICACIÓN",
                                titulo: 'Registro por Certificación',
                                posicion: 8,
                                imagen: "https://thumbs.dreamstime.com/b/l%C3%ADnea-icono-del-certificado-muestra-verificada-documento-124865043.jpg"
                            },
                        ])
                    }
                    this.$store.commit('setError', null)
                    this.$http.defaults.headers.common['Authorization'] = this.$store.state.session.token;
                    //this.getDatosRegistro();
                    this.$router.push('/rpa')
                }).catch(error => {
                    console.log('error iniciar session rpa');
                    console.log(error);
                });
        },
        cerrarSesion(axios, store) {
            axios = axios || this.$http;
            store = store || this.$store;
            console.log(this.$vuetify.breakpoint)
            if(this.$vuetify.breakpoint){
                if(this.$vuetify.breakpoint.xs){
                    this.$store.commit('setDrawer',false)
                }
            }
            store.commit('setMessageOverlay', 'Cerrando Sesion ...')
            store.commit('setOverlay', true);
            axios.get(`${this.$apiUrl}openid/logout`, {
                    params: {
                        token: store.state.session.token
                    }
                }).then(response => {
                    store.commit('setLoading', false)
                    store.commit('setUser', null)
                    store.commit('setToken', null)
                    store.commit('setModulos', null)
                    localStorage.clear();
                    window.location.replace(response.data.data.urlLogout)
                }).catch(error => {
                    store.commit('setLoading', false);
                    store.commit('setOverlay', false);
                    localStorage.clear();
                })
        },
        timerSession() {
            var store = this.$store;
            store.commit('SET_TIME', 30)
            store.commit('INIT_INTERVAL', window.setInterval(() => {
                store.commit('TIME_DECREASE')
                if (store.state.time <= 0) {
                    store.commit('DESTROY_INTERVAL')
                    window.clearInterval()
                    this.cerrarSesion();
                }
            }, 15000))
        },
        async getSession() {
           try{
            let data
            if (this.user === null || this.parametros === null) {
                this.$store.commit("setMessageOverlay", "Espere un momento ...");
                this.$store.commit("setOverlay", true)
                this.$http
                    .get(`${this.$apiUrl}abogado-ciudadano/session`)
                    .then(response => {
                    this.$storage.set("parametros", response.data.data.parametros);
                    this.$store.commit("setParametros", response.data.data.parametros);
                    this.$store.commit("abogado/setPersona",response.data.data.persona)
                    this.$storage.set("persona",response.data.data.persona)
                    this.$store.commit("abogado/setIsRegistered",response.data.data.isRegistered)
                    this.$storage.set("isRegistered",response.data.data.isRegistered)
                    this.$store.commit("setOverlay", false);
                    if(this.$store.state.user == null){
                        this.$storage.set("user", response.data.data.user);
                        this.$store.commit('setUser', response.data.data.user);
                        if(response.data.data.user.nombreUniversidad!=null){
                            this.$store.commit('addItemsMenu', [
                                {
                                    home: true,
                                    descripcion:
                                        "Registro de nuevos profesionales por parte de las Universidades y/o Ministerio de Educacion",
                                    path: "/registro-certificacion",
                                    icon: "mdi-text-box-check-outline",
                                    text: "REGISTRO POR CERTIFICACION",
                                    titulo: 'Registro por Certificacion',
                                    posicion: 8,
                                    imagen: "https://thumbs.dreamstime.com/b/l%C3%ADnea-icono-del-certificado-muestra-verificada-documento-124865043.jpg"
                                },
                            ])
                        }
                    }
                    if (response.data.data.isRegistered) {
                        this.getDatosRegistro();
                    }
                    }).catch(error=>{
                        console.log(error)
                    })
                return data
            }
            if (this.$store.state.abogado.isRegistered && this.$store.state.abogado.estadoAbogado === null) {
                //TODO verificar getdatosregistro 
                //this.getDatosRegistro();
            }
            }
            catch(error){
                console.log(error)
            }
        }
    }
}