<template>
    <v-layout align-center justify-center>
        <v-layout row wrap>
          <v-flex xs12>
            <v-img :src="require('../assets/rpa.png')" contain height="150"></v-img>
          </v-flex>
          <v-flex xs12 class="text-center">
            <v-progress-circular :size="50" color="orange" indeterminate></v-progress-circular>
            <span class="ml-5">Eliminando datos de la sesion espere un momento ...</span>
          </v-flex>
        </v-layout>
    </v-layout>
</template>
<script>
export default {
    mounted(){
        var storage = window.localStorage;
        for (var key in storage) {
            if (typeof storage[key] !== 'function' && key.indexOf(process.env.VUE_APP_NAME) === 0 ) {     
                window.localStorage.removeItem(key);
            }  
        }
       window.location='/'
    }
}
</script>