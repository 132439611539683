export default {
    app: {
        title: 'Registro Público de la Abogacía',
        short_title: 'RPA',
        institution: 'MINISTERIO DE JUSTICIA Y TRANSPARENCIA INSTITUCIONAL',
        body_login: '',
        rol: 'Rol',
        edit: 'Editar',
        made: 'GESTORA SAJ - URPA - TIC'
      },
      error: {
        '400': 'Hubo un error de validación.',
        '401': 'Su sesión ha caducado.',
        '412': 'No se pudo realizar la operación.',
        '403': 'No tiene los permisos necesarios.',
        '404': 'No existe el recurso solicitado.',
        '407': 'Necesita configurar su Proxy para poder usar el sistema.',
        '408': 'El servidor no responde intente más tarde.',
        '409': 'Los datos no son válidos',
        '410': 'El recurso solicitado ya no está disponible y no lo estará de nuevo.',
        '500': 'Se produjo un error en el servidor, inténtelo más tarde.',
        '502': 'El sistema no está disponible en estos momentos, inténtelo más tarde.',
        '503': 'El sistema se encuentra en mantenimiento en estos momentos, vuelva a intentarlo más tarde por favor.',
        //'504': 'Gateway timeout - Tiempo de espera agotado.', // Esto debe ser implementado en el frontend para reintentar las peticiones
        'connection': 'No se pudo establecer conexión con el servidor.',
        'cancelRequest': 'Petición cancelada.',
        'validation': 'Error de validación',
        'unknown': 'Ocurrió un error desconocido, intente más tarde.',
        'sessionExpired': 'Su sesión ha expirado, ingrese nuevamente.'
      }  
}