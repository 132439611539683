export const moduleCurso={
    namespaced: true,
    state:{
        cursos:[],
        registrosCursos:[]
    },
    mutations: {
        setCursos(state,payload){
            state.cursos=payload
        },
        setRegistrosCursos(state,payload){
            state.registrosCursos=payload
        },
        addRegistroCurso(state,payload){
            state.registrosCursos.unshift(payload)
        }
    },
    getters:{
        getRegistroCursoByCurso:(state)=>(idCurso)=>{
            return state.registrosCursos.filter(registro=>registro.idCurso===idCurso);
        }
    }
}