import { mapState } from "vuex";
export default {
    data(){
        return{
            loadingTable:false,
        }
    },
    computed:{
        ...mapState({ isRegistered: state => state.abogado.isRegistered }),
    }
}