<template>
  <v-app>
    <vue-progress-bar></vue-progress-bar>
     <notifications group="foo"  position="top right" classes="notificacion" :duration="4000" :width="350" />

      <Sidebar v-if="user"/>
      <Header v-if="user"/>

      <v-main>
        <v-container 
          grid-list-xl
          :class="{'ma-0 px-0 py-1':$vuetify.breakpoint.xsOnly,'mb-0 mt-0 ,py-0':$vuetify.breakpoint.smAndUp}"
          fluid
          :fill-height="fillHeight"
        >
          <v-layout row wrap justify-center>
            <template v-if="alerts.length>0">
              <v-flex xl12 xs12 lg12 md12 v-for="(item,index) in alerts" :key="index" class="py-2 mb-1">
                <v-alert 
                  border="left"
                  :type="item.type" 
                  :value="true" 
                  class="my-0 subtitle-2 font-weight-regular"
                  :class="item.class"
                  v-html="item.text"    
                /> 
              </v-flex>
            </template>
            <router-view></router-view>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fixed
                  dark
                  fab
                  bottom
                  right
                  class="button-whatsapp"
                  @click="openChat"
                  v-bind="attrs"
                  v-on="on"
                >
                  <img src="/whatsapp-logo.png" width="35px" />
                </v-btn>
              </template>
              <span>Solicitar ayuda al RPA mediante whatsapp</span>
            </v-tooltip>
          </v-layout>
        </v-container>
      </v-main>  
      
      <Footer v-if="user"/>

      <v-overlay :value="overlay" color="primary" style="z-index:999">
        <v-img :src="require('@/assets/rpaOpacidad10.png')" contain aspect-ratio="2.5"></v-img>
        <v-progress-circular indeterminate size="50" color="orange" class="mr-3"></v-progress-circular>
        {{messageOverlay}}
      </v-overlay>
      
 </v-app>
</template>
<script>
import Footer from "@/common/layout/AppFooter";
import Header from "@/common/layout/AppHeader";
import Sidebar from "@/common/layout/AppSidebar";
import { mapState } from "vuex";
import mixinRegistro from "@/mixins/registro";
import mixinAuth from "@/mixins/auth";

export default {
  name: "app",
   components: {
    Header,
    Sidebar,
    Footer
  },
  mixins:[mixinAuth,mixinRegistro],
  data(){
    return{
      fillHeight:false
    }
  },
  async created(){
    this.$Progress.start();
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        this.$Progress.parseMeta(meta);
      }
      this.$Progress.start();
      next();
    });
    this.$router.afterEach((to, from) => {
      this.$Progress.finish();
    });

    if (this.$store.state.session.token != null) {
      this.$http.defaults.headers.common[
        "Authorization"
      ] = 'Bearer '+this.$store.state.session.token;
        this.getSession()
    }
  },
  onIdle() {
     if (this.$store.state.session.token != null) {
       if(process.env.NODE_ENV === 'production'){
          this.cerrarSesion()
       }else{
         alert('cerrado por inactividad')
       }
     }
  },
  computed:{
    ...mapState({
      user: state => state.session.user,
      parametros:state=>state.session.parametros,
      overlay: state => state.application.overlay,
      messageOverlay: state => state.application.messageOverlay,
      alerts: state => state.session.alertas,
    })
  },
  mounted () {
    if(['Login','PasarelaPago'].includes(this.$route.name)){
      this.fillHeight=true
    }
    setTimeout(()=>{
      const ruta= this.$route.path
      if(!['/','/solicitud-aprobacion-documentos','/aprobar-documento-fake','/pago-ppe'].includes(ruta)){
        this.getDatosRegistro()
      }
    },100)
    this.$Progress.finish();
  },
  methods:{
    openChat(){
      window.open('https://wa.me/59171521522?text=tengo consulta sobre un tramite que brinda el rpa')
    }
  }
};
</script>
<style>

.notificacion.info {
  border-left-color: #187fe7 !important;
  background: #27C3EA;
}

.notificacion.warning {
  border-left-color: #f48a06 !important;
  background: #ffb822;
}

.notificacion.error {
  border-left-color: #b82e24 !important;
  background: #f4516c;
}

.notificacion.success {
  border-left-color: #009105 !important;
  background: #34bfa3;
}

.notificacion {
  padding: 10px !important;
  margin: 5px 5px 2px !important;
  font-size: 13px !important;
  font-family: Roboto, sans-serif !important;
  color: #ffffff;
  background: #44a4fc;
  border-left: 5px solid #187fe7;
}

.button-whatsapp{
  top:89vh;
  background-color: #00e676 !important;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
	0% {
		/* transform: scale(0.95); */
		box-shadow: 0 0 0 0 rgba(23, 197, 125, 0.7);
	}

	70% {
		/* transform: scale(1); */
		box-shadow: 0 0 0 13px rgba(0, 0, 0, 0);
	}

	100% {
		/* transform: scale(0.95); */
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

#app {
  background-color: rgba(0,0,0,.05) !important;
  background-image: url(assets/background.png) !important;
  background-repeat: repeat;
  background-size: 30% !important;
}

.v-card {
  border-radius: 15px !important;
}

.panels .v-expansion-panel{
    border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

</style>