import store from '@/store'
import {sumarDiasHabiles, getDiasHabilesRestantes, formatDateGlobal} from './funcionesFecha'

export function verificiarCredencialVencida (fecha){
    if (!fecha)return
    //const fechaCorteCredencial = store.state.abogado.fechaCorteCredencial
    //const fechaCorte = new Date(`${fechaCorteCredencial} 23:59:59`)
    let fechaVencimiento = new Date(`${fecha} 23:59:59`)
    /*if(fechaVencimiento <= fechaCorte){
        fechaVencimiento = fechaCorte
    }*/
    const fechaActual = new Date()
    const vencido = fechaVencimiento < fechaActual
    if(vencido){
        // const fechaLimite = sumarDiasHabiles(fechaVencimiento,20)
        // const diferenciaEnDias =  getDiasHabilesRestantes(fechaLimite)
        // const diasText = diferenciaEnDias ===0?'hoy':`en ${diferenciaEnDias} días hábiles`
        // let mensaje = `Su credencial caducó en fecha ${formatDateGlobal(fecha)},a partir de esta fecha tiene ${diasText} para realizar el proceso
        // de renovación y el desbloqueo de los servicios, <b>vencido el plazo se deberá realizar la solicitud de renovación, con el costo de registro y 
        // matriculación de acuerdo a lo establecido en el artículo 14 del Reglamento de Servicios del del Área de Registro de la Unidad de Registro Público de la Abogacía</b>`
        // if(fechaActual>fechaLimite){
        // mensaje = `Su credencial caducó en fecha ${formatDateGlobal(fecha)}, debera realizar la solicitud de renovación con el costo de 
        // registro y matriculación de acuerdo a lo establecido en el artículo 14 del Reglamento de Servicios del del Área de Registro de la Unidad de Registro Público de la Abogacía`
        // }
        const mensaje = `Usted solo podrá acceder a la opción “Reposición y Renovación de la Credencial”, su credencial caducó en fecha  ${formatDateGlobal(fecha)}, para el desbloqueo de los servicios, deberá realizar la solicitud de renovación`
        store.commit('addAlerta',{
            id: 'CREDENCIAL-0003',
            type: 'error',
            text: mensaje
        })
        store.commit('abogado/setCredencialVencido',true)
    }
    return vencido
}