export const moduleAbogado={
    namespaced: true,
    state:{
        isRegistered:localStorage.rpa_isRegistered ? JSON.parse(localStorage.rpa_isRegistered) : null,
        esDefensorRuanna:null,
        registro:{
            fotoAbogado:null,
            firmaAbogado:null
        },
        persona: localStorage.rpa_persona? JSON.parse(localStorage.rpa_persona):{
            nombres:'',
            fechaNacimiento:'',
            primerApellido:'',
            segundoApellido:'',
            celular:null,
            correo:null,
            whatsapp:null
        },
        direcciones:[],
        estadoAbogado:null,
        estadoRegistro:null,
        tipoRegistro:null,
        certificacionTitulo:{
            procedencia:null
        },
        conCodigoPago:false,
        cpt:null,
        metodoPago:{
            solicitudPasarelaPago:null,
            cpt:null,
            qr:null
        },
        copiaLegalizada:false,
        profesion:null,
        juramento:null,
        credenciales:[],
        certificaciones:[],
        experiencia:[],
        formacion:[],
        docencia:[],
        produccionIntelectual:[],
        defensor:{
            
        },
        credencialVencido:false,
        fechaCorteCredencial:null
    },
    mutations: {
        setRegistroAbogado(state, payload) {
            state.registro = payload;
        },
        setPersona(state,payload){
            state.persona=payload
        },
        setDirecciones(state,payload){
            state.direcciones=payload
        },
        addDireccion(state,payload){
            state.direcciones.push(payload)
        },
        setIsRegistered(state,payload){
            state.isRegistered=payload
        },
        setEstadoRegistro(state,payload){
            state.estadoRegistro=payload
        },
        setEstadoAbogado(state,payload){
            state.estadoAbogado=payload
        },
        setTipoRegistro(state,payload){
            state.tipoRegistro=payload
        },
        setProfesion(state,payload){
            state.profesion=payload
        },
        setCertificacionTitulo(state,payload){
            state.certificacionTitulo=payload
        },
        setJuramento(state,payload){
            state.juramento=payload
        },
        setCopiaLegalizada(state,payload){
            state.copiaLegalizada=payload
        },
        setPersonaContacto(state,payload){
            if(payload.correo){
                state.persona.correo=payload.correo
            }
            if(payload.celular){
                state.persona.celular=payload.celular
            }
            if(payload.whatsapp){
                state.persona.whatsapp=payload.whatsapp
            }             
        },
        setFormacion(state,payload){
            state.formacion=payload
        },
        setConCodigoPago(state, payload){
            state.conCodigoPago=payload
        },
        setCpt(state,payload){
            state.cpt=payload
        },
        setMetodoPago(state,payload){
            state.metodoPago=payload
        },
        setExperiencia(state,payload){
            state.experiencia=payload
        },
        setDocencia(state,payload){
            state.docencia=payload
        },
        setProduccionIntelectual(state,payload){
            state.produccionIntelectual=payload
        },
        removeFormacion(state,payload){
            let i = state.formacion.map(item => item.id).indexOf(payload)
            state.formacion.splice(i,1);
        },
        removeExperiencia(state,payload){
            let i = state.experiencia.map(item => item.id).indexOf(payload)
            state.experiencia.splice(i,1);
        },
        setCredencialVencido(state, payload){
            state.credencialVencido = payload
        },
        setFechaCorteCredencial(state, payload){
            state.fechaCorteCredencial = payload
        }
    },
    getters:{
        estaRegistrado(state){
            return state.isRegistered;
        },
        getOficinas(state){
            return state.direcciones.filter(direccion=>direccion.tipo.nombre==='OFICINA');
        },
        getDomicilios(state){
            return state.direcciones.filter(direccion=>direccion.tipo.nombre==='DOMICILIO');
        },
        getFotoAbogado(state){
            if(state.registro.fotoAbogado===null){
                return require('../assets/sinFoto.png');
            }else{
                return process.env.VUE_APP_DOC_URL+state.registro.fotoAbogado
            }
            
        },
        getFirmaAbogado(state){
            if(state.registro.firmaAbogado===null){
                return require('../assets/firmaBlanco.jpg');
            }else{
                return process.env.VUE_APP_DOC_URL+state.registro.firmaAbogado
            }   
        },
        getNombreCompleto(state){
            return `${state.persona.nombres} ${state.persona.primerApellido} ${state.persona.segundoApellido}`;
        },
        getDireccionesAbogado:(state)=>(active)=>{
            if(!active){
                return state.direcciones
            }else{
                return state.direcciones.filter(direccion=>direccion.activo);
            }
        },
        getMetodoPago(state){
            return state.registro.metodoPago || null;
        }
    }

}