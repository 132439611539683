import axios from 'axios';
export default {
    methods: {
        tokenOpenID() {
            const params = new URLSearchParams(window.location.search);
            let _code, _state, _sessionState;
            if (params.get('code') && params.get('state')) {
                this.$store.commit('setMessageOverlay', 'Validando Autenticacion de Ciudadanía Digital ...')
                this.$store.commit('setOverlay', true)
                _code = params.get('code');
                _state = params.get('state');
                //_sessionState = params.get('session_state');          
               axios.get(`${this.$apiUrl}openid/token`, {
                    params: {
                        code: _code,
                        state: _state,
                        //session_state: _sessionState
                    }
                }).then((response) => {
                    this.$store.commit('setOverlay', false)
                    this.$Progress.finish();
                    const token = response.data.data.token;
                    const modulos = response.data.data.modulos;
                    this.$storage.set("token", token);
                    this.$store.commit('setToken', token)
                    this.$storage.set("user", response.data.data.usuario);
                    this.$store.commit('setUser', response.data.data.usuario);
                    if(response.data.data.usuario.nombreUniversidad!=null){
                        this.$store.commit('addItemsMenu', [
                            {
                                home: true,
                                descripcion:
                                    "Registro de nuevos profesionales por parte de las Universidades y/o Ministerio de Educacion",
                                path: "/registro-certificacion",
                                icon: "mdi-text-box-check-outline",
                                text: "REGISTRO POR CERTIFICACION",
                                titulo: 'Registro por Certificacion',
                                posicion: 8,
                                imagen: "https://thumbs.dreamstime.com/b/l%C3%ADnea-icono-del-certificado-muestra-verificada-documento-124865043.jpg"
                            },
                        ])
                    }
                    this.$storage.set("modulos", modulos);
                    this.$store.commit('setModulos', modulos);
                    this.$http.defaults.headers.common[
                        "Authorization"
                      ] = 'Bearer '+this.$store.state.session.token;
                    this.getSession()
                    this.$router.push('/rpa')
                    
                }).catch(error => {
                    console.log(error)
                    console.error('error en Ciudadanía Digital al solicitar token');
                    this.$store.commit('setOverlay', false)
                    this.$router.push({ name: 'Login' });
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Ciudadanía Digital",
                        text: 'No se pudo realizar la autenticación de Ciudadanía Digital'
                    });
                })
            } else if (params.get('error') && params.get('error_description')) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error Ciudadanía Digital",
                    text: params.get('error_description')
                });
                this.$router.push({ name: 'Login' });
            }
        },

        loginOpenID(data) {
            this.loading_ciudadania=true;
            axios.get(`${this.$apiUrl}openid/login`).then(response => {
                this.$Progress.finish();
                window.location.href = response.data.data.urlAutorizacion;
            }).catch((error) => {
                this.$Progress.finish();
                this.loading_ciudadania=false
                if (error) {
                    this.$Progress.fail();
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error Ciudadanía Digital",
                        text: 'El Servicio de Ciudadanía Digital tiene problemas, contactese con el administrador'
                    });
                }
            });
        },

        logoutOpenID() {
            const url = 'openid/logout';
            this.$service.post(url).then((response) => {
                if (response.finalizado) {
                    const store = this.$store;
                    if (store.state.sessionInterval) {
                        window.clearInterval(store.state.sessionInterval);
                        store.state.sessionInterval = null;
                    }
                    this.$storage.removeUser();
                    this.$storage.remove('menu');
                    this.$storage.remove('token');
                    this.$storage.remove('sidenav');
                    this.$storage.remove('permissions');
                    store.commit('setDefault');
                    window.location.href = response.datos.urlLogout;
                } else {
                    this.$message.error('No se pudo cerrar sesión');
                }
            }).catch(() => {
                this.$message.error('No se pudo cerrar sesión');
            });
        }
    }
};
