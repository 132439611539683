import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import routes from './routes'
import {verificarCaducidad} from '../mixins/funcionesFecha'


Vue.use(Router)



const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.query.continue){
    localStorage.setItem(`${process.env.VUE_APP_NAME}_continue`,to.query.continue)
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.session.token == null) {
      if(process.env.NODE_ENV === 'production'){
        next({name:'Login'})
      }else{
        console.log('sin token')
        next({name:'Login'})
      }
    }
    if(to.matched.some(record => record.meta.verificar)){
      const abogado = store.state.abogado
      let credencialVencida = false
      if(abogado.estadoAbogado === 'INHABILITADO'){
        if(!abogado.credencialVencido){
          credencialVencida = verificarCaducidad(abogado.registro.fechaCredencialVencimiento)
          if(credencialVencida && to.name!=='Credenciales'){
            if(from.name !== 'Home'){
              next({name:'Home'})
            }
          }else{
            next()
          }
        }
      }
      if(!credencialVencida){
        next()
      }
    }else{
      next()
    }
  } else {
    if(to.name == 'Login' && store.state.session.token != null){
      next({name:'Home'})
    }else{
      next();
    }
  }
})

export default router