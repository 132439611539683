import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#253854',
        secondary: '#0E698B',
        accent: '#86D7C1',
        error: '#f4516c',
        info: '#27C3EA',
        success: '#34bfa3',
        warning: '#ffb822'
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'mdi',
  }
});
