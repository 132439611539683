import Login from '../views/Login'
import Logout from '../views/Logout'
import Home from '../views/Home'

const routes = [
    {
      path: '/',
      name: 'Login',
      component: Login
    },
    {
      path:'/logout',
      name: 'Logout',
      component: Logout
    },
    {
      path: '/aprobar-documento-fake',
      name: 'aprobacion-ciudadania',
      component: ()=>import('../views/AprobacionCiudadania.vue')
    },
    {
      path: '/rpa',
      name: 'Home',
      component: Home,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/abogado',
      name: 'Abogado',
      meta: {
        requiresAuth: true,
      },
      redirect: { name: 'AbogadoRegistro'},
      component: () => import('../views/Abogado/index.vue'),
      children: [
        {
          path: 'registro',
          name: 'AbogadoRegistro',
          component: () => import('../views/Abogado/AbogadoRegistro.vue'),
          meta: {
            requiresAuth: true,
            verificar:true
          },
        },
        {
          path: 'registro-persona',
          name: 'AbogadoPersona',
          component: () => import('../views/Abogado/AbogadoPersona.vue'),
          meta: {
            requiresAuth: true,
            verificar:true,
          },
        },
        {
          path: 'formacion-academica',
          name: 'AbogadoFormacion',
          component: () => import('../views/Abogado/AbogadoFormacion.vue'),
          meta: {
            requiresAuth: true,
            verificar:true
          },
        },
        {
          path: 'experiencia-laboral',
          name: 'AbogadoExperiencia',
          component: () => import('../views/Abogado/AbogadoExperiencia.vue'),
          meta: {
            requiresAuth: true,
            verificar:true
          },
        },
        {
          path: 'certificaciones',
          name: 'Certificaciones',
          meta: {
            requiresAuth: true,
            verificar:true
          },
          component: () => import('../views/Abogado/Certificaciones.vue'),
        },
        {
          path: 'credenciales',
          name: 'Credenciales',
          meta: {
            requiresAuth: true,
            verificar:true
          },
          component: () => import('../views/Abogado/Credenciales.vue'),
        },
        {
          path: 'sanciones',
          name: 'ControlEjercicioProfesional',
          meta: {
            requiresAuth: true,
            verificar:true
          },
          component: () => import('../views/Abogado/ControlEjercicioProfesional.vue'),
        },
        {
          path: 'sociedades-civiles',
          name: 'SociedadesCiviles',
          meta: {
            requiresAuth: true,
            verificar:true
          },
          component: () => import('../views/Abogado/SociedadesCiviles.vue'),
        },
      ]
    },
    {
      path:'/convocatorias',
      name: 'Convocatorias',
      component: ()=>import('../views/Convocatoria/index.vue')
    },
    {
      path:'/convocatorias/:id',
      name: 'ConvocatoriaPostulacion',
      component: ()=>import('../views/Convocatoria/Postulacion.vue')
    },
    {
      path: '/ruanna',
      name: 'Ruanna',
      meta: {
        requiresAuth: true
      },
      redirect: { name: 'DefensorRegistro'},
      component: () => import('../views/Ruanna/index.vue'),
      children:[
        {
          path: 'defensor',
          name: 'DefensorRegistro',
          component: () => import('../views/Ruanna/DefensorRegistro.vue'),
          meta: {
            requiresAuth: true,
            verificar:true
          },
        },
        {
          path: 'defensor-persona',
          name: 'DefensorPersona',
          component: () => import('../views/Abogado/AbogadoPersona.vue'),
          meta: {
            requiresAuth: true,
            verificar:true
          },
        },
        {
          path: 'defensor-formacion',
          name: 'DefensorFormacion',
          component: () => import('../views/Abogado/AbogadoFormacion.vue'),
          meta: {
            requiresAuth: true,
            verificar:true
          },
        },
        {
          path: 'defensor-experiencia',
          name: 'DefensorExperiencia',
          component: () => import('../views/Abogado/AbogadoExperiencia.vue'),
          meta: {
            requiresAuth: true,
            verificar:true
          },
        },
        {
          path: 'defensor-causas',
          name: 'DefensorCausas',
          component: () => import('../views/Abogado/AbogadoExperiencia.vue'),
          meta: {
            requiresAuth: true,
            verificar:true
          },
        },
        {
          path: 'bandeja',
          name: 'DefensorBuzon',
          component: () => import('../views/Abogado/AbogadoExperiencia.vue'),
          meta: {
            requiresAuth: true,
            verificar:true
          },
        }
      ]
    },
    {
      path: '/solicitud-aprobacion-documentos',
      name: 'SolicitudAprobacionDocumentos',
      component: () => import('../views/SolicitudAprobacionDocumentos/index.vue'),
      meta:{
        requiresAuth:true
      }
    },
    {
      path: '/pago-ppe',
      name: 'PasarelaPago',
      component: () => import('../views/PasarelaPago/index.vue'),
      meta:{
        requiresAuth:true
      }
    },
    {
      path:'/cursos',
      name:'Cursos',
      component:()=>import('../views/Curso/index.vue'),
      meta: {
        requiresAuth: true,
      }
    },
    {
      path:'/buscar',
      name:'busqueda',
      component:()=>import('../views/Busqueda/index.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path:'/registro-certificacion',
      name:'registro-certificacion',
      component:()=>import('../views/RegistroCertificacionTitulo/index.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '*',
      name: 'NotFoundResource',
      component: ()=>import("@/common/layout/pages/AppNotFound"),
    }
  ]

  export default routes