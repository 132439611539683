<template>
  <v-container fluid grid-list-md>
    <v-layout row wrap>
      <v-flex
        v-for="item in areasUsuario"
        :key="item.titulo"
        xl2 lg3 md4 sm6 xs12
        class="item"
        v-if="item.home"
      >
        <v-hover >
          <v-card
          @click="redirectToPath(item)"
            
            slot-scope="{ hover }"
            :class="`elevation-${hover ? 23 : 3}`"
            height="100%"
          >
            <v-card-text class="py-2">
              <v-layout row wrap>
                <v-flex lg12 md12 sm12 xs3>
                  <v-img :src="item.imagen" :aspect-ratio="$vuetify.breakpoint.mdAndUp?1.9 :1"></v-img>
                </v-flex>
                <v-flex lg12 md12 sm12 xs9>
                  <v-card-title primary-title>
                    <div>
                      <div class="subtitle-2 font-weight-black">{{item.titulo}}

                        <v-chip v-if="item.nuevo" color="error" small darl>NUEVO</v-chip>
                      </div>
                    </div>
                  </v-card-title>
                  <v-card-text class="py-0">
                  
                      <div>{{item.descripcion}}</div>
           
                  </v-card-text>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
export default {
  mounted() {
    
    const params = new URLSearchParams(window.location.search);
    if (params.get("error") && params.get("error_description")) {
      this.$notify({
        group: "foo",
        type: "error",
        title: "Error de Acceso",
        text: params.get("error_description")
      })
    }
     if(this.user.nombreUniversidad!=null){
          this.$store.commit('addItemsMenu', [
              {
                  home: true,
                  descripcion:
                      "Registro de nuevos profesionales por parte de las Universidades y/o Ministerio de Educacion",
                  path: "/registro-certificacion",
                  icon: "mdi-text-box-check-outline",
                  text: "REGISTRO POR CERTIFICACION",
                  titulo: 'Registro por Certificacion',
                  posicion: 8,
                  imagen: "https://thumbs.dreamstime.com/b/l%C3%ADnea-icono-del-certificado-muestra-verificada-documento-124865043.jpg"
              },
          ])
      }
  },
  computed: {
    areasUsuario() {
      return this.$store.getters.getMenuItemsByPosicion;
    },
    ...mapState({
      user: state => state.session.user,
      parametros:state=>state.session.parametros
    })
  },
  methods:{
    redirectToPath(item) {
      this.$store.commit("setTituloPricipal", item.titulo);
      if (item.path) {
        return this.$router.push(item.path);
      }
    }
  }
};
</script>
