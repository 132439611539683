'use strict'

import axios from 'axios';
import store from '@/store';
import router from '@/router';
import Auth from '@/mixins/auth';

export default {
    store,
    install: (Vue, config) => {

        const apiUrl = config && config.apiUrl ? config.apiUrl : '';
        const apiSegUrl=config && config.apiSegUrl ? config.apiSegUrl : '';
        const docUrl = config && config.docUrl ? config.docUrl : '';
        const authToken = config && config.authToken ? config.authToken : 'Bearer';

        let instance = new Vue({
           mixins: [Auth]
        });
        
        const Message = instance.$notify;
        const errorsLangEs = store.state.application.msg.error;

        axios.interceptors.request.use(function (config) {
            instance.$Progress.start();
            if (store.state.time) {
                store.commit('SET_TIME', process.env.TIME_SESSION_EXPIRED * 60);
            }
            return config;
        }, function (error) {
            return Promise.reject(error);
        });

        // Add a response interceptor
        axios.interceptors.response.use(function (response) {
            instance.$Progress.finish();
            if (response.data.mensaje) {
                Message({
                    group: "foo",
                    type: "success",
                    title: "Peticion Correcta",
                    text: response.data.mensaje,
                });
            }
            return response;
        }, function (error) {
            instance.$Progress.fail();
            if (error.response) {
                if (error.response.status === 400) {
                    let mensaje = error.response?.data?.mensaje 
                    const data = error.response.data
                    if(data?.data?.code === 400){
                        if(data.data?.errors){
                            mensaje += '<ul>'
                            for (const key in data.data?.errors?.children) {
                                const errorsForm = data.data?.errors?.children[key]?.errors
                                if(errorsForm){
                                    mensaje += `<li><strong>${key}</strong> ${errorsForm[0]}</li>`
                                }
                            }
                            mensaje += '</ul>'
                        }
                    }
                    Message({
                        group: "foo",
                        type: "error",
                        title: "Error al realizar la Peticion",
                        text:  mensaje
                    });
                }
                if (error.response.status === 401) {
                    Message({
                        group: "foo",
                        type: "error",
                        title: "Error al realizar la Peticion",
                        text: errorsLangEs['sessionExpired']
                    });
                    console.log(error.response.data.message == 'Expired JWT Token')
                   if(error.response.data.message == 'Expired JWT Token'){
                        instance.cerrarSesion(axios,store);
                    }
                   
                }
                if (error.response.status === 403) {
                    store.state.state403 = true;
                    Message({
                        group: "foo",
                        type: "warning",
                        title: "Acceso Prohibido",
                        text: "Usted no tiene acceso a esta Ruta"
                    });
                    router.push('/prohibido');
                }
                if (error.response.status === 404) {
                    Message({
                        group: "foo",
                        type: "error",
                        title: "Recurso no encontrado",
                        text: "El recurso solicitado no se pudo ser encontrado"
                    });
                }
                if (error.response.status === 500) {
                    let data = error.response.data;
                    let message = null;
                    /*if (process.env.DEBUG_MODE) {
                        if (data.mensaje || data.message) {
                            message = data.mensaje || data.message;
                        } else if (typeof data === 'object') {
                            let text = [];
                            for (let key in data) {
                                text.push(`${key}: ${data[key]}`);
                            }
                            message = text.join('<br />');
                        }
                    }*/
                    Message({
                        group: "foo",
                        type: "error",
                        title: "Error en el Servidor",
                        text: data.error.exception
                    });
                }
            } else if (error.message) {
                if (error.message === 'Network Error') {
                    Message({
                        group: "foo",
                        type: "error",
                        title: "Error al realizar la Peticion",
                        text: errorsLangEs['connection']
                    });
                } else {
                    Message.error(error.message);
                    Message({
                        group: "foo",
                        type: "error",
                        title: "Error al realizar la Peticion",
                        text: error.message
                    });
                }
            } else {
                Message({
                    group: "foo",
                    type: "error",
                    title: "Error al realizar la Peticion",
                    text: errorsLangEs['unknown']
                });
            }
            return Promise.reject(error);
        });

        Vue.prototype.$http = axios;
        Vue.prototype.$apiUrl = apiUrl;
        Vue.prototype.$apiSegUrl=apiSegUrl;
        Vue.prototype.$authToken = authToken;
        Vue.prototype.$docUrl = docUrl;
    }
};