import store from '@/store'

export function getObjectDate(fecha){
  return typeof fecha === 'string'? new Date(`${fecha} 23:59:59`):fecha
}

export function sumarDiasHabiles(fecha, dias){
    let fechaResultado = getObjectDate(fecha);
    let diasHabilesAgregados = 0;
    while (diasHabilesAgregados < dias) {
        fechaResultado.setDate(fechaResultado.getDate() + 1);
        // Verificar si el día actual no es un fin de semana (sábado o domingo)
        if (fechaResultado.getDay() !== 0 && fechaResultado.getDay() !== 6) {
        diasHabilesAgregados++;
        }
    }
    return fechaResultado;
}

export function getDiasRestantes(fechaFin,fechaInicio='now'){
    const hoy = fechaInicio!=='now'?new Date(fechaInicio+ "23:59:59"):new Date()
    let fecha = getObjectDate(fechaFin)
    const diferenciaEnMilisegundos = fecha - hoy
    return Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24))
}

export function getDiasHabilesRestantes(fechaFin, fechaInicio='now'){
  let inicio = fechaInicio!=='now'?new Date(fechaInicio+ "23:59:59"):new Date()
  const fin = getObjectDate(fechaFin)
  let dias = 0
  while (inicio<fin){
    inicio.setDate(inicio.getDate() + 1)
    if (inicio.getDay() !== 0 && inicio.getDay() !== 6) {
      dias++;
    }
  }
  return dias;
}

export function verificarCaducidad(fechaCredencialVencimiento){
    // const fechaCorteCredencial = store.state.abogado.fechaCorteCredencial
    // const fechaCorte = new Date(`${fechaCorteCredencial} 23:59:59`)
    let fechaVencimiento = new Date(`${fechaCredencialVencimiento} 23:59:59`)
    // if(fechaVencimiento<=fechaCorte){
    //  fechaVencimiento = fechaCorte
    // }
    //const fechaLimite = sumarDiasHabiles(fechaVencimiento,20)
    const fechaLimite = fechaVencimiento
    const fechaActual = new Date()
    // const diferenciaEnDias =  getDiasRestantes(fechaLimite)
    return fechaActual<fechaLimite
}

export function formatDateGlobal(date) {
  if (!date) return null;
  const [dates, hours] = date.split(" ");
  if (date.indexOf("-") > 0) {
    const [year, month, day] = dates.split("-");
    return `${day}/${month}/${year}`;
  }
  return date;
}
