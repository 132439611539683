import Vue from 'vue'
import VueProgressBar from 'vue-progressbar'
import Notifications from 'vue-notification'

import App from './App.vue'
import router from './router/index'
import store from './store/index'

import vuetify from './plugins/vuetify';

import Service from './common/plugins/service';
import Storage from './common/plugins/storage';

import IdleVue from "idle-vue";

import mGlobal from '@/mixins/global';
Vue.mixin(mGlobal);



import VueTour from 'vue-tour'
require('vue-tour/dist/vue-tour.css')

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: process.env.VUE_APP_TIME_IDLE || 600000 , // 10 minutos
});

Vue.use(Notifications)

Vue.use(Storage);

Vue.use(Service, {
  apiUrl: process.env.VUE_APP_API_RPA_URL,
  apiSegUrl:process.env.VUE_APP_API_SEGURIDAD_URL,
  authToken: process.env.VUE_APP_AUTH_TOKEN,
  docUrl: process.env.VUE_APP_DOC_URL,
  errorFormat: 'message'
});

Vue.use(VueProgressBar, {
  color: '#86D7C1',
  failedColor: '#fc4b6c',
  thickness: '6px',
});

Vue.use(VueTour)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
