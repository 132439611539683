var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"fixed":"","clipped-left":_vm.clipped,"app":"","color":"primary","dark":"","height":"55"}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.cambiarDrawer()}}}):_vm._e(),_c('v-toolbar-title',{staticClass:"h6 font-weight-bold"},[_vm._v(_vm._s(_vm.tituloPrincipal))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{'mr-10':_vm.isDevelopment},attrs:{"icon":""},on:{"click":function($event){return _vm.getDatosRegistro()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Actualizar página")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{'ml-10':_vm.isDevelopment},attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-apps")])],1)]}}])},[_c('span',[_vm._v("Aplicaciones")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":"red","overlap":"","value":_vm.notificaciones.length,"content":_vm.notificaciones.length}},[_c('v-icon',[_vm._v("mdi-bell")])],1)],1)]}}])},[_c('span',[_vm._v("Notificaciones")])]),(_vm.isDevelopment)?_c('div',{staticClass:"baner-development"},[_vm._v(" AMBIENTE DE PRUEBA ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }