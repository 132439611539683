import Vue from 'vue'
import Vuex from 'vuex'

import {moduleApplication} from './application'
import {moduleMenu} from './menu'
import {moduleSession} from './session'
import {moduleAbogado} from './abogado'
import {moduleCurso} from './curso'


Vue.use(Vuex)

export default new Vuex.Store({
  modules:{
    menu: moduleMenu,
    abogado: moduleAbogado,
    application:moduleApplication,
    session:moduleSession,
    curso:moduleCurso
  },
  state:{

  },
  mutations:{

  },
  actions:{
    
  }
})
