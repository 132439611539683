export const moduleSession={
    state:{
        token: localStorage.rpa_token ? JSON.parse(localStorage.rpa_token) : null,
        user: localStorage.rpa_user ? JSON.parse(localStorage.rpa_user) : null,
        modulos: localStorage.rpa_modulos ? JSON.parse(localStorage.rpa_modulos) : [],
        notificaciones:[],
        alertas:[],
        parametros:localStorage.rpa_parametros ? JSON.parse(localStorage.rpa_parametros) : null,
    },
    mutations: {
          setUser(state, payload) {
            state.user = payload
          },
          setModulos(state, payload) {
            state.modulos = payload;
          },
          setParametros(state,payload){
            state.parametros = payload;
          },
          setToken(state,payload){
            state.token=payload
          },
          setAlertas(state,payload){
            state.alertas= payload
          },
          setRefreshToken(state,payload){
            state.refresh_token=payload
          },
          addAlerta(state,payload){
            var contain = false
            state.alertas.forEach(element => {
              if(element.id == payload.id){
                  contain = true
              }
            });
            if(!contain){
              state.alertas.push(payload)
            }
          }
    },
    getters:{
      getParameter:(state)=>(tipo,nombre)=>{
        return state.parametros[tipo].find(
          x => x.text === nombre
        )
      },
      getAlertas: (state)=>{
        return state.alertas
      }
    },
    actions:{
      deleteAlertasPasarela({commit,state}){
        const todo = [...state.alertas]
        commit('setAlertas',todo.filter(e=>!e.id.includes('PPE')))
      }
    }
}